import React, {useState,useEffect} from 'react';
import * as Realm from "realm-web";
import * as bootstrap from 'bootstrap';
import _ from 'lodash'

import $ from "jquery";
import { loading } from '../constant';

import { DataGrid } from '@mui/x-data-grid';
import QuickSearchToolbar from '../components/MUI-QuickSearchToolBar';
import CustomPagination from '../components/MUI-DGPagination';

const realmapp = new Realm.App({id: "khaosatnguoihoc-rndsu"});
const credentials = Realm.Credentials.anonymous();

// const getData = async (folder,name) =>{
//     return await fetch('data/'+folder+name+'.json')
//     .then(response => response.json())
//     .then(data => {return data});
// }

function Users(props){
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [ojbData, setObjData] = useState({});
    // const [driver, setDriver] = useState();
    // const [car, setCar] = useState();
    // const [carNumber, setCarNumber] = useState();
    // const [carry, setCarry] = useState();
    // const [from, setFrom] = useState();
    // const [to, setTo] = useState();
    // const [when, setWhen] = useState();
    // const [status, setStatus] = useState();

    useEffect(()=>{
        async function dataName(params){
            const realmUser = await realmapp.logIn(credentials);
            setData(await realmUser.callFunction('getStaff', {}));
        }
        dataName();
    },[]);

    const columns = [
        { field: 'displayName', headerName: 'Nhân viên',    headerAlign: 'center', flex: 1},
        { field: 'email',       headerName: 'Email',        headerAlign: 'center', flex: 1},
        { field: 'device',      headerName: 'Tên thiết bị',     headerAlign: 'center', flex: 0.5,   align: "center"},
        { field: 'code',        headerName: 'Mã PIN',       headerAlign: 'center', flex: 0.5,   align: "center"},
        // {
        //   field: 'fullName',
        //   headerName: 'Full name',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    const getRandomCode = () => {
        return Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    };

    async function handleAddUser(e){
        e.preventDefault();
        var tmp = {};
        for(var i = 0; i < e.target.length-2; i++){
            tmp[`${e.target[i].id}`] = e.target[i].value;
        }
        (tmp.code=="")?tmp.code = getRandomCode():tmp.code = tmp.code;
        tmp = {...tmp, img: ""};
        const realmUser = await realmapp.logIn(credentials);
        const tmp2 = await realmUser.callFunction('insertUser', tmp);
        console.log(tmp2.rs);
        window.location.reload();
    }

    async function handleUpdateUser(e){
        e.preventDefault();
        (ojbData.code=="")?ojbData.code = getRandomCode():ojbData.code = ojbData.code;
        const realmUser = await realmapp.logIn(credentials);
        const tmp = await realmUser.callFunction('updateStaff', {_id: ojbData._id}, {
            $set: {
                "displayName": ojbData.displayName,
                "email": ojbData.email,
                "device": ojbData.device,
                "code": ojbData.code
            }
        });
        console.log(tmp);
        window.location.reload();
    }

    async function handleDelUser(e){
        e.preventDefault();
        const realmUser = await realmapp.logIn(credentials);
        const tmp = await realmUser.callFunction('deleteStaff', {_id: {$in: selected}});
        console.log(tmp);
        window.location.reload();
    }

    const handleChange = (e)=>{
        $(document).ready(function(){
            setSelected(e);
            setObjData(data.find(dt => dt._id == e[0]));
            if(_.isEmpty(e)){
                $('#updateButton').addClass("Mui-disabled");
                $('#deleteButton').addClass("Mui-disabled");
            }else{
                if(e.length === 1){
                    $('#updateButton').removeClass("Mui-disabled");
                    $('#updateButton').removeAttr("disabled");
                }else{
                    $('#updateButton').addClass("Mui-disabled");
                }
                $('#deleteButton').removeClass("Mui-disabled");
                $('#deleteButton').removeAttr("disabled");
            }
        });
    }

    return(
        <>
            <div className="main vh-100 d-flex align-items-center justify-content-center">
                {(_.isEmpty(data))?
                <div><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>{loading}</div>:
                <div className="container p-3 shadow rounded">
                    <h3 className="pt-2 pb-2">DANH SÁCH NHÂN VIÊN</h3>
                    <div style={{height: "690px", width: "100%"}}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection
                            getRowId={(row) => row._id}
                            onSelectionModelChange={handleChange}
                            disableSelectionOnClick
                            components={{
                                Toolbar: QuickSearchToolbar,
                                Pagination: CustomPagination,
                            }}
                            componentsProps={{
                                toolbar: { 
                                    idAddModal: "#addUserModal",
                                    idUpdateModal: "#updateUserModal",
                                    idDeleteModal: "#deleteUserModal"
                                },
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'createAt', sort: 'desc' }],
                                },
                            }}
                        />
                    </div>
                </div>
                }
            </div>
            <div className="modal fade" id="addUserModal" tabindex="-1" aria-labelledby="addUserModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addUserModalLabel">THÊM NHÂN VIÊN</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleAddUser}>
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="displayName" placeholder="Tên hiển thị" required/>
                                    <label for="displayName">Tên hiển thị</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" id="email" placeholder="Email" required />
                                    <label for="email">Email</label>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="device" placeholder="Thiết bị" />
                                            <label for="device">Tên thiết bị</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="code" placeholder="Mã PIN" />
                                            <label for="code">Mã PIN</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">Hủy</button>
                                    <button type="submit" className="btn btn-primary">Thêm</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="updateUserModal" tabindex="-1" aria-labelledby="updateUserModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="updateUserModalLabel">CẬP NHẬT THÔNG TIN NHÂN VIÊN</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleUpdateUser}>
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="displayName" placeholder="Tên hiển thị" onChange={(e) => setObjData({...ojbData, displayName: e.target.value})} value={ojbData.displayName} required/>
                                    <label for="displayName">Tên hiển thị</label>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="email" class="form-control" id="email" placeholder="Email" onChange={(e) => setObjData({...ojbData, email: e.target.value})} value={ojbData.email} required/>
                                    <label for="email">Email</label>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="device" placeholder="Thiết bị" onChange={(e) => setObjData({...ojbData, device: e.target.value})} value={ojbData.device}/>
                                            <label for="device">Tên thiết bị</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="form-floating mb-3">
                                            <input type="text" class="form-control" id="code" placeholder="Mã PIN" onChange={(e) => setObjData({...ojbData, code: e.target.value})} value={ojbData.code}/>
                                            <label for="code">Mã PIN</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-secondary me-2" data-bs-dismiss="modal">Hủy</button>
                                    <button type="submit" className="btn btn-primary">Sửa</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="deleteUserModal" tabindex="-1" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="deleteUserModalLabel">Xác nhận</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h2 className="mb-4">Bạn thực sự muốn xóa ?</h2>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="btn btn-secondary me-2 w-100" data-bs-dismiss="modal">Hủy</button>
                                <button type="button" className="btn btn-primary w-100" onClick={handleDelUser}>Xóa</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );    
}
export default Users;