import React, {useState,useEffect} from 'react'
import * as Realm from "realm-web";
import * as bootstrap from 'bootstrap';
import _ from 'lodash';
import { DataGrid } from '@mui/x-data-grid';
import Chart from "react-apexcharts";

import { loading } from '../constant';
import CustomPagination from '../components/MUI-DGPagination';
import CustomToolbar from '../components/MUI-DGToolbar';
import BSCard from '../components/BS-Card';

const realmapp = new Realm.App({id: "khaosatnguoihoc-rndsu"});
const credentials = Realm.Credentials.anonymous();

// const getData = async (folder,name) =>{
//     return await fetch('data/'+folder+name+'.json')
//     .then(response => response.json())
//     .then(data => {return data});
// }

function Dashboard(props){
    const [data, setData] = useState([]);
    // const [driver, setDriver] = useState(); 
    // const [car, setCar] = useState();
    // const [carNumber, setCarNumber] = useState();
    // const [carry, setCarry] = useState();
    // const [from, setFrom] = useState();
    // const [to, setTo] = useState();
    // const [when, setWhen] = useState();
    // const [status, setStatus] = useState();
    
    useEffect(()=>{
        async function dataName(params){
            const realmUser = await realmapp.logIn(credentials);
            setData(await realmUser.callFunction('getRating', {}));
        }
        dataName();
    },[]);

    function countRating(data){
        var staff = [];
        var rate = [];
        for(var dt of data){
            var tot = 0, kha = 0;
            var c = 0;
            for(var s of staff){
                if(dt.displayName === s) c++;
            }
            if(dt.rating === "Tốt") tot++;
            if(dt.rating === "Khá") kha++;
            if(c === 0){
                staff.push(dt.displayName);
                rate.push({
                    tot: tot,
                    kha: kha,
                    staff: dt.displayName,
                    tong: tot + kha
                })
            }else{
                for(var r of rate){
                    if(r.staff === dt.displayName){
                        r.kha+=kha;
                        r.tot+=tot;
                        r.tong=r.tot+r.kha;
                    }
                }
            }
        }
        return rate;
    }

    var rate = countRating(data);
    rate.sort((a,b)=>b.tong-a.tong);
    var staff = [], tot = [], kha = [];
    for(var r of rate){
        staff.push(r.staff);
        tot.push(r.tot);
        kha.push(r.kha);
    }

    var options = {
        chart: {
            id: "top5Chart",
            type: "bar",
            stacked: true
        },
        xaxis: {
            categories: staff
        }
    };

    var series = [{
        name: 'Số lượt đánh giá Tốt',
        data: tot
    },{
        name: 'Số lượt đánh giá Khá',
        data: kha
    }];

    var tot2 = 0, kha2 = 0, binhthuong2 = 0, kem2 = 0;
    for(var d of data){
        // eslint-disable-next-line default-case
        switch(d.rating){
            case "Tốt": tot2++; break;
            case "Khá": kha2++; break;
            case "Bình thường": binhthuong2++; break;
            case "Kém": kem2++; break;
        }
    }

    var option2 = {
        labels: ["Tốt", "Khá", "Bình thường", "Kém"],
    }

    var series2 = [tot2, kha2, binhthuong2, kem2];
        
    return(
        <>
            <div className="main">
                {(_.isEmpty(data))?
                <div className="vh-100 d-flex justify-content-center">
                    <div className="align-self-center">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>{loading}
                    </div>
                </div>:
                <>
                    <div className="row m-0 p-3">
                        <BSCard href="/users" color="#4e73df" title="Nhân viên" statusText="Quản lý nhân viên" icon={<i className="bi bi-person-fill fs-1" style={{color: "#dddfeb"}}></i>}/>
                        <BSCard href="/pins" color="#1cc88a" title="PIN" statusText="Quản lý mã pin" icon={<i class="bi bi-upc-scan fs-1"style={{color: "#dddfeb"}}></i>}/>
                        <BSCard href="/history" color="#f6c23e" title="Lịch sử" statusText="Quản lý lịch sử đánh giá" icon={<i className="bi bi-clock-fill fs-1" style={{color: "#dddfeb"}}></i>}/>
                    </div>
                    <div className="row m-0 p-3">
                        <div className="col-xl-6">
                            <div className="p-3 shadow rounded">
                                <h3 className="text-center pt-2 pb-2">TOP 5 NHÂN VIÊN CÓ ĐÁNH GIÁ CAO TRONG THÁNG</h3>
                                <div style={{height: "520px"}}>
                                    <Chart options={options} series={series} type="bar"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="p-3 shadow rounded">
                                <h3 className="text-center pt-2 pb-2">TỔNG SỐ LƯỢT ĐÁNH GIÁ TRONG THÁNG</h3>
                                <div className="donut" style={{height: "520px"}}>
                                    <Chart height="520" options={option2} series={series2} type="donut"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </>
    );
}
export default Dashboard;