import React from 'react';
import './App.css';
import {Route, BrowserRouter} from 'react-router-dom';

import SideBar from './components/BS-SideBar'

import Login from './pages/Login';
import Index from './pages/Index';
import Users from './pages/Users';
import History from './pages/History';

function App() {
  const isAuthenticated = sessionStorage.getItem('isAuthenticated');
  return (
    <BrowserRouter>
      {isAuthenticated?
      <div className="d-flex">
        <SideBar />
        <Route exact path="/home" component={Index} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/history" component={History} />
      </div>:
      <Route component={Login}/>
      }
    </BrowserRouter>
  );
}

export default App;