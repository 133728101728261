import React, {useState,useEffect} from 'react';
import * as Realm from "realm-web";
import * as bootstrap from 'bootstrap';
import _ from 'lodash'

import $ from "jquery";
import { loading } from '../constant';

import { DataGrid } from '@mui/x-data-grid';
import CustomToolbar from '../components/MUI-DGToolbar';
import CustomPagination from '../components/MUI-DGPagination';

const realmapp = new Realm.App({id: "khaosatnguoihoc-rndsu"});
const credentials = Realm.Credentials.anonymous();

// const getData = async (folder,name) =>{
//     return await fetch('data/'+folder+name+'.json')
//     .then(response => response.json())
//     .then(data => {return data});
// }

function History(props){
    const [data, setData] = useState([]);
    // const [driver, setDriver] = useState();
    // const [car, setCar] = useState();
    // const [carNumber, setCarNumber] = useState();
    // const [carry, setCarry] = useState();
    // const [from, setFrom] = useState();
    // const [to, setTo] = useState();
    // const [when, setWhen] = useState();
    // const [status, setStatus] = useState();

    useEffect(()=>{
        async function dataName(params){
            const realmUser = await realmapp.logIn(credentials);
            setData(await realmUser.callFunction('getRating', {}));
        }
        dataName();
    },[]);

    function prettyTime(number){
        if(number < 10){
            return `0${number}`;
        }else{
            return number;
        }
    }

    function date(dateString){
        const d = new Date(dateString);
        return prettyTime(d.getDate())+"/"+prettyTime(d.getMonth()+1)+"/"+prettyTime(d.getFullYear())+" - "+prettyTime(d.getHours())+":"+prettyTime(d.getMinutes())+":"+prettyTime(d.getSeconds());
    }

    useEffect(()=>{
        if(!_.isEmpty(data)){
            for(var dt of data){
                dt.createAt = date(dt.createAt);
            }
        }
    })

    const columns = [
        { field: 'displayName', headerName: 'Nhân viên',    headerAlign: 'center', flex: 1},
        { field: 'email',       headerName: 'Email',        headerAlign: 'center', flex: 1},
        { field: 'rating',      headerName: 'Đánh giá',     headerAlign: 'center', flex: 0.5,   align: "center"},
        { field: 'createAt',    headerName: 'Vào lúc',      headerAlign: 'center', flex: 2,     align: "center"},
        // {
        //   field: 'fullName',
        //   headerName: 'Full name',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    return(
        <>
            <div className="main vh-100 d-flex align-items-center justify-content-center">
                {(_.isEmpty(data))?
                <div><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>{loading}</div>:
                <div className="container p-3 shadow rounded">
                    <h3 className="pt-2 pb-2">LỊCH SỬ ĐÁNH GIÁ</h3>
                    <div style={{height: "680px", width: "100%"}}>
                        <DataGrid
                            rows={data}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection
                            getRowId={(row) => row._id}
                            components={{
                                Toolbar: CustomToolbar,
                                Pagination: CustomPagination,
                            }}
                            initialState={{
                                sorting: {
                                    sortModel: [{ field: 'createAt', sort: 'desc' }],
                                },
                            }}
                        />
                    </div>
                </div>
                }
            </div>
        </>
    );    
}
export default History;